<template>
  <b-card title="Kategori Skor Motivasi">
    <!-- chart -->
    <chartjs-component-doughnut-chart
      :height="275"
      :data="chartjsData.doughnutChart3.data"
      :options="chartjsData.doughnutChart3.options"
      class="mb-3"
    />
    <!--/ chart -->

    <!-- stocks -->
    <div
      v-for="(stock,index) in stockData"
      :key="stock.device"
      :class="index < stockData.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="stock.symbol"
          size="16"
          :class="stock.color"
        />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <span>- {{ stock.percentage }}%</span>
      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  data() {
    return {
      chartjsData,
      stockData: [
        {
          device: 'Tinggi', symbol: 'TrendingUpIcon', color: 'text-primary', percentage: 35, upDown: 2,
        },
        {
          device: 'Rendah', symbol: 'TrendingDownIcon', color: 'text-warning', percentage: 65, upDown: 8,
        },
      ],
    }
  },
}
</script>
