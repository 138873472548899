<template>
  <b-card title="Jenis Kelamin">
    <!-- chart -->
    <chartjs-component-doughnut-chart
      :height="275"
      :data="dataChart.data"
      :options="dataChart.options"
      class="mb-3"
      :key="componentKey"
    />
    <!--/ chart -->

    <!-- stocks -->
    <div
      v-for="(stock,index) in stockData"
      :key="stock.device"
      :class="index < stockData.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="stock.symbol"
          size="16"
          :class="stock.color"
        />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <!-- <span>- {{ stock.percentage }}</span> -->
      </div>
      <div>
        <span>{{ stock.upDown }}</span>
        <!-- <feather-icon
          :icon="stock.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
          :class="stock.upDown > 0 ? 'text-success':'text-danger'"
        /> -->
      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  props: {
    chartjsDataAnalytics: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.datasets[0].labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} %`
                return output
              },
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: [],
              data: [],
              backgroundColor: ['#28dac6', '#FDAC34', $themeColors.primary],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
      stockData: [
        {
          device: 'Total User Terdaftar', symbol: 'UsersIcon', color: 'text-primary', percentage: 2042, upDown: 100,
        },
        {
          device: 'Laki-Laki', symbol: 'UserIcon', color: 'text-primary', percentage: 1449, upDown: 70,
        },
        {
          device: 'Perempuan', symbol: 'HeartIcon', color: 'text-warning', percentage: 593, upDown: 30,
        },
      ],
      componentKey: 0,
    }
  },
  mounted() {
    this.dataChart.data.datasets[0].labels = this.chartjsDataAnalytics.jeniskelamin
    this.dataChart.data.datasets[0].data = this.chartjsDataAnalytics.jumlah
    this.stockData = this.chartjsDataAnalytics.stockData
    this.componentKey = 1
  },
}
</script>
