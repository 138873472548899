<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filter
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Nama"
            label-for="account-username"
          >
            <b-form-input
              v-model="namaUserFilter"
              placeholder="Nama"
              name="username"
              class="form-control"
              @input="(val) => $emit('update:namaUserFilter', val)"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Umur"
            label-for="account-username"
          >
            <b-row>
              <b-col
                cols="4"
                md="3"
              >
                <b-form-input
                  v-model="umurAwalFilter"
                  placeholder="17"
                  name="username"
                  class="form-control"
                  @input="(val) => $emit('update:umurAwalFilter', val)"
                  col="3"
                  md="3"
                />
              </b-col>
              <b-col
                cols="1"
                md="1"
                class="pt-md-1 pt-1"
              >
                <label>s/d</label>
              </b-col>
              <b-col
                cols="4"
                md="3"
              >
                <b-form-input
                  v-model="umurAkhirFilter"
                  placeholder="35"
                  name="username"
                  class="form-control"
                  @input="(val) => $emit('update:umurAkhirFilter', val)"
                  col="3"
                  md="3"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col> -->
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
            <b-form-group
            label="Jenis Kelamin"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="jenisKelaminFilter"
              :options="jenisKelaminOptions"
              class="w-100"
              multiple
              @input="(val) => $emit('update:jenisKelaminFilter', val)"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Provinsi"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="provinsiFilter"
              :options="provinsiOptions"
              :reduce="val => val.value"
              @input="(val) => changeProv(val)"
              class="w-100"
            />
          </b-form-group>
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Kota"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="kotaFilter"
              :options="kotaOptions"
              class="w-100"
              @input="(val) => $emit('update:kotaFilter', val)"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
          v-if="$can('pemantauan', 'proyek')"
        >
          <b-form-group
            label="Proyek"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="searchForm.proyek"
              :options="proyekOptions"
              :reduce="val => val.value"
              class="w-100"
              @input="(val) => changeKegiatan(val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Kegiatan"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="searchForm.kegiatan"
              :options="kegiatanOptions"
              class="w-100"
              multiple
              @input="(val) => changeFilter(val)"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Periode Awal Proyek"
          >
            <flat-pickr
              v-model="searchFilter.tanggal_mulai"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Periode Akhir Proyek"
          >
            <flat-pickr
              v-model="searchFilter.tanggal_akhir"
              class="form-control"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col
          cols="12"
        >
          <b-button
            variant="primary"
            @click="cariData(searchFilter)"
            class="mr-1"
          >
            <span class="text-nowrap">Cari</span>
          </b-button>
        </b-col> -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormGroup,
    // BFormInput,
    // flatPickr,
    // BFormCheckbox,
    // BButton,
    // Cleave,
  },
  props: {
    kegiatanOptions: {
      type: Array,
      required: true,
    },
    proyekOptions: {
      type: Array,
      required: true,
    },
    namaUserFilter: null,
    jenisKelaminFilter: null,
    provinsiFilter: null,
    kotaFilter: null,
    proyekFilter: null,
    umurAwalFilter: null,
    umurAkhirFilter: null,
    kegiatanFilter: null,
    /*
    searchFilter: {
      nama: '',
      private: [
        {
          id: 0,
          label: 'Publik',
        },
        {
          id: 1,
          label: 'Privat',
        },
      ],
    },
    */
  },
  data() {
    return {
      searchForm: {
        proyek: null,
        kegiatan: null,
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      statusOption: [
        {
          id: 0,
          label: 'Publik',
        },
        {
          id: 1,
          label: 'Privat',
        },
      ],
    }
  },
  mounted() {
    if (!this.$can('pemantauan', 'proyek')) {
      this.changeKegiatan(0)
    }
    // const d = new Date()
    // const tanggalAwal = d.setMonth(d.getMonth() - 6)
    // this.searchFilter.tanggal_mulai = tanggalAwal
    // const dn = new Date()
    // const tanggalAkhir = dn.setMonth(dn.getMonth() + 3)
    // this.searchFilter.tanggal_akhir = tanggalAkhir
  },
  methods: {
    changeKegiatan(value) {
      this.$emit('emitReceiverKegiatan', value)
      this.$emit('emitFilter', this.searchForm)
    },
    changeFilter(value) {
      this.$emit('emitFilter', this.searchForm)
      // this.$emit('update:proyekFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
