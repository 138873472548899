import {
  get,
} from './helpers'

function getDashboard() {
  return get('/dashboard')
}

function getDashboardPemantauan(jenis) {
  return get(`/dashboard/pemantauan/${jenis}`)
}

function getDashboardPemantauanFilter(jenis, proyek, kegiatan) {
  return get(`/dashboard/pemantauan/filter/${jenis}/${proyek}/${kegiatan}`)
}

/* eslint-disable import/prefer-default-export */
export {
  getDashboard,
  getDashboardPemantauan,
  getDashboardPemantauanFilter,
}
