<template>
  <b-card>
    <b-card-title class="mb-1">
      Pekerjaan Terakhir
    </b-card-title>
    <b-card-sub-title class="mb-2">

    </b-card-sub-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
      :key="componentKey"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    chartjsDataAnalytics: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      componentKey: 0,
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'top',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  // show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Operational',
                    formatter() {
                      return '31%'
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    this.donutChart.chartOptions.labels = this.chartjsDataAnalytics.statuspekerjaan
    this.donutChart.series = this.chartjsDataAnalytics.jumlah
    this.componentKey = 1
  },
}
</script>
