<template>
  <div>
    <list-filter
    :proyekOptions="proyekOptions.options"
    :kegiatanOptions="kegiatanOptions.options"
    @emitFilter="refetchData"
    @emitReceiverKegiatan="reloadKegiatanOptions"
    />
    <b-row class="match-height"
      v-if="showFilter"
    >
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="analyticsData"
          icon="UsersIcon"
          :statistic="kFormatter(analyticsData.subscribers)"
          statistic-title="Total Users"
          :chart-data="analyticsData.series"
        />
      </b-col>
      <b-col
        v-if="$can('pemantauan', 'proyek')"
        xl="3"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="BriefcaseIcon"
          :statistic="analyticsData.proyek"
          statistic-title="Proyek Aktif"
          color="info"
          class="pt-3"
        />
      </b-col>
      <b-col
        xl="3"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="CalendarIcon"
          :statistic="analyticsData.kegiatan"
          statistic-title="Kegiatan Aktif"
          class="pt-3"
        />
      </b-col>
      <!-- <b-col
        xl="3"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="danger"
          icon="MessageSquareIcon"
          statistic="97.8k"
          statistic-title="Data Kontak Kami"
          class="pt-3"
        />
      </b-col> -->
    </b-row>
    <b-row class="match-height"
      v-if="showFilter"
    >
      <b-col md="6">
        <chartjs-bar-chart
          v-if="chartjsDataAnalyticsUmur.umur"
          :componentKey="componentKey"
          :dataChart="dataChart"
        />
      </b-col>
      <b-col md="6">
        <chartjs-polar-area-chart
          v-if="chartjsDataAnalyticsPendidikan.pendidikan"
          :chartjsDataAnalytics="chartjsDataAnalyticsPendidikan"
        />
      </b-col>
      <b-col lg="4">
        <chartjs-doughnut-chart
          v-if="chartjsDataAnalyticsJenisKelamin.jeniskelamin"
          :chartjsDataAnalytics="chartjsDataAnalyticsJenisKelamin"
        />
      </b-col>
      <b-col lg="4">
        <status-pekerjaan
          v-if="chartjsDataAnalyticsStatusPekerjaan.statuspekerjaan"
          :chartjsDataAnalytics="chartjsDataAnalyticsStatusPekerjaan"
        />
      </b-col>
      <b-col lg="4">
        <jenis-disabilitas
          v-if="chartjsDataAnalyticsJenisDisabilitas.jenisdisabilitas"
          :chartjsDataAnalytics="chartjsDataAnalyticsJenisDisabilitas"
        />
      </b-col>
      <!-- <b-col
        v-if="$can('pemantauan', 'proyek')"
        lg="4"
      >
        <chartjs-doughnut-chart5 />
      </b-col>
      <b-col
        v-if="$can('pemantauan', 'proyek')"
        lg="4"
      >
        <chartjs-doughnut-chart3 />
      </b-col>
      <b-col
        v-if="$can('pemantauan', 'proyek')"
        lg="4"
      >
        <chartjs-doughnut-chart4 />
      </b-col> -->
      <!-- <b-col lg="8">
        <chartjs-scatter-chart />
      </b-col>
      <b-col cols="12">
        <chartjs-line-area-chart />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import {
  getDashboardPemantauan, getDashboardPemantauanFilter,
} from '@/connection-api/dashboard'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { kFormatter } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  getAllProyek,
} from '@/api/proyek'
import {
  getAllKegiatanByProyek, getAllKegiatan,
} from '@/api/kegiatan'
import ChartjsPolarAreaChart from './ChartjsPolarAreaChart.vue'
import ChartjsDoughnutChart from './ChartjsDoughnutChart.vue'
import StatusPekerjaan from './StatusPekerjaan.vue'
import JenisDisabilitas from './JenisDisabilitas.vue'
import ChartjsDoughnutChart2 from './ChartjsDoughnutChart2.vue'
import ChartjsDoughnutChart3 from './ChartjsDoughnutChart3.vue'
import ChartjsDoughnutChart4 from './ChartjsDoughnutChart4.vue'
import ChartjsDoughnutChart5 from './ChartjsDoughnutChart5.vue'
import ChartjsHorizontalBarChart from './ChartjsHorizontalBarChart.vue'
import ChartjsBubbleChart from './ChartjsBubbleChart.vue'
import ChartjsScatterChart from './ChartjsScatterChart.vue'
import ChartjsBarChart from './ChartjsBarChart.vue'
import ChartjsLineChart from './ChartjsLineChart.vue'
import ListFilter from './ListFilters.vue'

export default {
  components: {
    BRow,
    BCol,
    // BCardCode,
    // BLink,

    ChartjsBarChart,
    // ChartjsLineChart,
    // ChartjsLineAreaChart,
    // ChartjsRadarChart,
    ChartjsPolarAreaChart,
    ChartjsDoughnutChart,
    StatusPekerjaan,
    JenisDisabilitas,
    // ChartjsDoughnutChart2,
    // ChartjsDoughnutChart3,
    // ChartjsDoughnutChart4,
    // ChartjsDoughnutChart5,
    StatisticCardVertical,
    // StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    ListFilter,
    // StatisticCardWithLineChart,
    // CardStatisticOrderChart,
    // CardStatisticProfitChart,
    // CardStatisticsGroup,
    // ChartjsHorizontalBarChart,
    // ChartjsScatterChart,
    // ChartjsBubbleChart,
    // BFormGroup,
    // BFormInput,
    // vSelect,
  },
  data() {
    return {
      componentKey: 0,
      showFilter: false,
      filter: {},
      // Area charts
      analyticsData: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      // Line Charts
      siteTraffic: {},
      activeUsers: {},
      newsletter: {},
      proyekOption: ['Proyek Jakarta', 'Proyek Bandung', 'Proyek Malang'],
      kegiatanOption: ['Outbond', 'Charity', 'Sepak Bola', 'Menjahit'],
      chartjsDataAnalyticsUmur: {},
      chartjsDataAnalyticsPendidikan: {},
      chartjsDataAnalyticsJenisKelamin: {},
      chartjsDataAnalyticsStatusPekerjaan: {},
      chartjsDataAnalyticsJenisDisabilitas: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      proyekOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Proyek --' },
        ],
      },
      kegiatanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kegiatan --' },
        ],
      },
      dataChart: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#2c9aff',
            borderColor: 'transparent',
          },
        ],
      },
    }
  },
  async mounted() {
    // Subscribers gained
    try {
      // const response = await getDashboardPemantauan('top')
      // this.analyticsData = response.data.data

      // const responseUmur = await getDashboardPemantauan('umur')
      // this.chartjsDataAnalyticsUmur = responseUmur.data.data
      // this.dataChart.labels = this.chartjsDataAnalyticsUmur.umur
      // this.dataChart.datasets[0].data = this.chartjsDataAnalyticsUmur.jumlah

      // const responsePendidikan = await getDashboardPemantauan('pendidikan')
      // this.chartjsDataAnalyticsPendidikan = responsePendidikan.data.data

      // const responseJenisKelamin = await getDashboardPemantauan('jenis-kelamin')
      // this.chartjsDataAnalyticsJenisKelamin = responseJenisKelamin.data.data

      const responseP = await getAllProyek()
      this.mappingSelectOption(responseP.data.data, this.proyekOptions.options)
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    kFormatter,
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    async reloadKegiatanOptions(proyekId) {
      this.kegiatanOptions = {
        selected: 'id',
        options: [
          { value: null, label: 'Pilih Kegiatan' },
        ],
      }
      if (proyekId > 0) {
        const response = await getAllKegiatanByProyek(1, proyekId)
        this.mappingSelectOption(response.data.data, this.kegiatanOptions.options)
      } else {
        const response = await getAllKegiatan(1, { user_id: this.userData.id })
        this.mappingSelectOption(response.data.data, this.kegiatanOptions.options)
      }
    },
    async refetchData(dataFilter) {
      if (dataFilter.kegiatan !== null || dataFilter.proyek !== null) {
        this.showFilter = true
        this.componentKey += 2
        const response = await getDashboardPemantauanFilter('top', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
        this.analyticsData = response.data.data
        const responseUmur = await getDashboardPemantauanFilter('umur', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
        this.chartjsDataAnalyticsUmur = responseUmur.data.data
        this.dataChart.labels = null
        this.dataChart.datasets[0].data = null
        this.refetchDataReply(this.chartjsDataAnalyticsUmur)
        const responsePendidikan = await getDashboardPemantauanFilter('pendidikan', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
        this.chartjsDataAnalyticsPendidikan = responsePendidikan.data.data

        const responseJenisKelamin = await getDashboardPemantauanFilter('jenis-kelamin', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
        this.chartjsDataAnalyticsJenisKelamin = responseJenisKelamin.data.data

        const responseStatusPekerjaan = await getDashboardPemantauanFilter('status-pekerjaan', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
        this.chartjsDataAnalyticsStatusPekerjaan = responseStatusPekerjaan.data.data

        const responseJD = await getDashboardPemantauanFilter('jenisdisabilitas', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
        this.chartjsDataAnalyticsJenisDisabilitas = responseJD.data.data
      }
    },
    async refetchDataReply(dataFilter) {
      this.componentKey += 2
      this.dataChart.labels = null
      this.dataChart.datasets[0].data = null
      this.dataChart.labels = dataFilter.umur
      this.dataChart.datasets[0].data = dataFilter.jumlah

      // const responsePendidikan = await getDashboardPemantauanFilter('pendidikan', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
      // this.chartjsDataAnalyticsPendidikan = responsePendidikan.data.data

      // const responseJenisKelamin = await getDashboardPemantauanFilter('jenis-kelamin', dataFilter.proyek, JSON.stringify(dataFilter.kegiatan))
      // this.chartjsDataAnalyticsJenisKelamin = responseJenisKelamin.data.data
    },
  },
}
</script>
